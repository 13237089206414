<template>
  <div
    class="rounded py-4 px-2 color-bg"
    :style="`background-color: ${colorBg};`"
  >
    <div :style="cssVars">
      <div v-for="(item, i) in data" :key="i" class="border-dashed">
        <p
          v-if="i === 0"
          class="border-solid mb-0 pb-2"
          :class="padded ? 'pl-4' : 'pl-6'"
        >
          <span>{{ item.label }}</span>
          <span class="fw-700 ml-2">{{ item.value }}</span>
        </p>
        <div v-else class="py-2" :class="padded ? 'mx-8' : 'mx-6'">
          <span>{{ item.label }}:</span>
          <span class="ml-1">{{ item.value }}</span>
          <span class="ml-2 font-14px">{{ item.secondary }}</span>
          <ul class="text-888 font-14px mt-2 report-ul">
            <li v-for="(el, j) in item.list" :key="j">
              <span class="ml-1">{{ el.label }}</span>
              <span class="ml-1">{{ el.value }}</span>
              <span class="ml-2 font-14px">{{ el.secondary }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "monthlyHeaderData",
  props: {
    data: Array,
    colorBg: String,
    padded: Boolean,
    colorLine: {
      type: String,
      default: "#dcd9c2",
      required: false
    }
  },
  computed: {
    cssVars() {
      return {
        /* variables you want to pass to css */
        "--colorLine": this.colorLine
      };
    }
  }
};
</script>

<style lang="scss">
.border-solid {
  border-bottom: 1px solid var(--colorLine);
}
.border-dashed:not(:first-child):not(:last-child) {
  border-bottom: 1px dashed var(--colorLine);
}
</style>
