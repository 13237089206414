<template>
  <div class="monthly-main" :style="cssVars">
    <HeaderData
      class="mb-5"
      :data="headerData"
      :colorBg="colorBg"
      :colorLine="colorLine"
      padded
    ></HeaderData>
    <v-card
      class="business-days"
      height="70px"
      width="311px"
      :color="colorBg"
      flat
    >
      <div class="d-flex align-center justify-center business-days-container">
        <v-icon size="27">$calendar</v-icon>
        <span class="business-days-title font-9px">営業日数</span>
        <div class="ml-2 text-333">
          <span class="fw-700 font-16px">{{ numberOfWorkingDays }}</span>
          <span class="font-10px business-days-number">日</span>
        </div>
      </div>
    </v-card>
    <v-row no-gutters>
      <!-- left side -->
      <v-col class="mr-1 mt-1">
        <SaleDistribution
          height="224px"
          width="155px"
          barHeight="12px"
          iconSize="20px"
          :backgroundColor="colorBg"
          :barColor="colorSvg"
          fontSize="font-14px"
          :data="distributionData"
          innerBarSpacing="mb-4"
          spacingBelowTitle="pb-6"
        ></SaleDistribution>
        <v-row no-gutters>
          <!-- All Customers -->
          <v-col>
            <v-card
              class="margin-top"
              height="72px"
              width="73px"
              :color="colorBg"
              flat
            >
              <div class="average-customer-title font-9px text-center">
                総組数
              </div>
              <div class="d-flex justify-center">
                <v-icon class="translate-left mt-1">$allCustomers</v-icon>
              </div>
              <div class="text-333 fw-700 font-16px text-center">
                {{ totalCustomer | formatNumber }}
                <span class="font-10px">人</span>
              </div>
            </v-card>
          </v-col>
          <v-col>
            <!-- All Customers In Store -->
            <v-card
              class="margin-top ml-1"
              height="72px"
              width="70px"
              :color="colorBg"
              flat
            >
              <div class="average-customer-title font-9px text-center">
                店内利用客数
              </div>
              <div class="d-flex justify-center">
                <v-icon class="translate-left mt-1"
                  >$allCustomersInStore</v-icon
                >
              </div>
              <div class="text-333 fw-700 font-16px text-center">
                {{ totalInStoreCustomer | formatNumber }}
                <span class="font-10px">人</span>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- right side -->
      <v-col class="ml-1">
        <!-- Cumilative Sales -->
        <v-card
          class="cumilative-sales margin-top "
          height="70px"
          width="155px"
          :color="colorBg"
          flat
        >
          <div class="d-flex align-center cumilative-sales-container">
            <v-icon size="30" class="mt-1">$sale</v-icon>
            <div>
              <span class="cumilative-sales-title font-9px">累計売上</span>
              <div class="ml-2 mt-n1 text-333">
                <span class="font-12px fw-700">￥</span>
                <span class="fw-700 font-16px">{{ sales | formatNumber }}</span>
              </div>
            </div>
          </div>
        </v-card>
        <v-card
          class="average-sales margin-top"
          height="70px"
          width="155px"
          :color="colorBg"
          flat
        >
          <div class="d-flex align-center average-sales-container">
            <v-icon size="34" class="mt-1">$averageSales</v-icon>
            <div>
              <span class="average-sales-title font-9px">平均売上</span>
              <div class="ml-2 mt-n1 text-333">
                <span class="font-12px fw-700">￥</span>
                <span class="fw-700 font-16px">{{
                  averageSales | formatNumber
                }}</span>
              </div>
            </div>
          </div>
        </v-card>
        <v-row no-gutters class="my-1">
          <!-- Average Sales Per Customer -->
          <v-col class="mr-1">
            <v-card
              class="mt-1"
              height="72px"
              width="75px"
              :color="colorBg"
              flat
            >
              <div class="average-customer-title font-9px text-center">
                客単価
              </div>

              <div
                class="text-333 fw-700 font-16px text-center average-customer-cost"
              >
                <span class="font-10px mr-n1">￥</span>
                {{ averageCustomerSpend | formatNumber }}
              </div>
              <div class="d-flex justify-center">
                <v-icon size="30" class="mt-1">$customerUnit</v-icon>
              </div>
            </v-card>
          </v-col>
          <v-col>
            <!-- Average In Store Customer Spend -->
            <v-card
              class="mt-1"
              height="72px"
              width="75px"
              :color="colorBg"
              flat
            >
              <div class="average-customer-title font-9px text-center">
                店内利用客単価
              </div>

              <div
                class="text-333 fw-700 font-16px text-center average-customer-cost"
              >
                <span class="font-10px mr-n1">￥</span>
                {{ averageInStoreCustomerSpend | formatNumber }}
              </div>
              <div class="d-flex justify-center">
                <v-icon class="margin-top">$instoreCustomerSpend</v-icon>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <!-- Number Of Pairs  -->
          <v-col>
            <v-card
              class="margin-top"
              height="72px"
              width="75px"
              :color="colorBg"
              flat
            >
              <div class="average-customer-title font-9px text-center">
                総組数
              </div>
              <div class="d-flex justify-center">
                <v-icon class="translate-left mt-1" size="26">$pair</v-icon>
              </div>
              <div class="text-333 fw-700 font-16px text-center">
                {{ totalGroup | formatNumber }}
                <span class="font-10px">組</span>
              </div>
            </v-card>
          </v-col>
          <v-col>
            <!-- Number of Groups -->
            <v-card
              class="margin-top ml-1"
              height="72px"
              width="75px"
              :color="colorBg"
              flat
            >
              <div class="average-customer-title font-9px text-center">
                店内利用組数
              </div>
              <div class="d-flex justify-center">
                <v-icon class="mt-1">$groups</v-icon>
              </div>
              <div class="text-333 fw-700 font-16px text-center">
                {{ totalInStoreGroup | formatNumber }}
                <span class="font-10px">組</span>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="d-flex justify-center align-center mt-10 mb-6 flex-column">
      <DoughnutChart :showLabels="false" :labels="labels">
        <template v-slot:title>
          <p class="text-888 font-12px mb-9">
            {{ currentDate.format("M") }}月 当日売上構成比率
          </p>
        </template>
        <template v-slot:inner-text>
          <div class="font-12px font-weight-bold">累計売上</div>
          <div class="font-weight-bold font-14px">
            <span>￥</span>{{ sales | formatNumber }}
          </div>
        </template>
      </DoughnutChart>
    </div>
  </div>
</template>

<script>
import DoughnutChart from "@/components/layout/budget/DoughnutChart";
import HeaderData from "./HeaderData";
import SaleDistribution from "@/components/layout/budget/SaleDistribution";
import dayjs from "@/plugins/dayjs";

export default {
  name: "monthly-earnings",
  components: {
    SaleDistribution,
    DoughnutChart,
    HeaderData
  },
  props: {
    colorBg: String,
    colorSvg: String,
    colorLine: String,
    id: String,
    monthlyReport: {
      type: Object,
      default: () => {}
    },
    currentDate: {
      default: dayjs()
    }
  },
  data() {
    return {
      barColor: "#6E77F0",
      // always should be sorted
      numberOfWorkingDays: 0,
      averageSales: 0,
      sales: 0,
      averageCustomerSpend: 0,
      averageInStoreCustomerSpend: 0,
      totalCustomer: 0,
      totalInStoreCustomer: 0,
      totalGroup: 0,
      totalInStoreGroup: 0,
      monthlyReportDetail: {},
      distributionData: this.getMealsPercent(),
      // remove after api integration
      headerData: this.getHeaderData(),
      labels: this.getDounutCosts()
    };
  },
  mounted() {
    this.fillMonthlyData();
  },
  computed: {
    cssVars() {
      return {
        /* variables you want to pass to css */
        "--colorSvg": this.colorSvg
      };
    }
  },
  methods: {
    getDounutCosts() {
      return [
        {
          color: "#7662E5",
          percentage: this.monthlyReport?.percentage?.food_earnings ?? 0,
          text: "フード",
          price: this.monthlyReport?.food_earnings ?? 0
        },
        {
          color: "#D8D1FD",
          percentage: this.monthlyReport?.percentage?.drinks_earnings ?? 0,
          text: "ドリンク",
          price: this.monthlyReport?.drinks_earnings ?? 0
        },
        {
          color: "#E8DF9E",
          percentage: this.monthlyReport?.percentage?.delivery_earnings ?? 0,
          text: "デリバリー ",
          price: this.monthlyReport?.delivery_earnings ?? 0
        },
        {
          color: "#FFA8E2",
          percentage: this.monthlyReport?.percentage?.takeout_earnings ?? 0,
          text: "テイク アウト",
          price: this.monthlyReport?.takeout_earnings ?? 0
        },
        {
          color: "#F4F4F4",
          percentage: this.monthlyReport?.percentage?.other_earnings ?? 0,
          text: "その他",
          price: this.monthlyReport?.other_earnings ?? 0
        }
      ];
    },
    fillMonthlyData() {
      this.numberOfWorkingDays = this.monthlyReport?.business_days_count;
      this.averageSales = this.monthlyReport?.total_earnings_avg;
      this.sales = this.monthlyReport?.total_earnings;
      this.averageCustomerSpend = this.monthlyReport?.average_sales_per_customer;
      this.averageInStoreCustomerSpend = this.monthlyReport?.average_sales_per_customer_in_store;

      this.totalCustomer = this.monthlyReport?.number_of_customers;
      this.totalInStoreCustomer = this.monthlyReport?.number_of_customer_store;
      this.totalGroup = this.monthlyReport?.number_of_groups;
      this.totalInStoreGroup = this.monthlyReport?.number_of_group_store;
    },

    getHeaderData() {
      return [
        {
          label: "売上",
          value: this.priceFormat(this.monthlyReport?.total_earnings)
        },
        {
          label: "フード",
          value: this.priceFormat(this.monthlyReport?.food_earnings)
        },
        {
          label: "ドリンク",
          value: this.priceFormat(this.monthlyReport?.drinks_earnings)
        },
        {
          label: "デリバリー",
          value: this.priceFormat(this.monthlyReport?.delivery_earnings),
          secondary: " (" + this.monthlyReport?.delivery_cases + "件) "
        },
        {
          label: "テイクアウト",
          value: this.priceFormat(this.monthlyReport?.takeout_earnings),
          secondary: " (" + this.monthlyReport?.takeout_cases + "件) "
        },
        {
          label: "その他",
          value: this.priceFormat(this.monthlyReport?.other_earnings)
        }
      ];
    },

    getMealsPercent() {
      return [
        {
          percentage: this.monthlyReport?.percentage?.food_earnings ?? "0",
          icon: "$dining"
        },
        {
          percentage: this.monthlyReport?.percentage?.drinks_earnings ?? "0",
          icon: "$tea"
        },
        {
          percentage: this.monthlyReport?.percentage?.delivery_earnings ?? "0",
          icon: "$delivery"
        },
        {
          percentage: this.monthlyReport?.percentage?.takeout_earnings ?? "0",
          icon: "$takeAwayWithDrink"
        },
        {
          percentage: this.monthlyReport?.percentage?.other_earnings ?? "0",
          icon: "$takeAway"
        }
      ];
    }
  }
};
</script>

<style lang="scss">
.margin-top {
  margin-top: 6px;
}
.business-days {
  .business-days-container {
    height: 100% !important;
    .business-days-title {
      color: #6a727a;
      margin-left: 14px !important;
    }
    .business-days-number {
      margin-left: 2px;
    }
  }
}
.cumilative-sales {
  .cumilative-sales-container {
    margin-left: 12px;
    height: 100% !important;
    .cumilative-sales-title {
      color: #6a727a;
      margin-left: 10px !important;
    }
  }
}
.average-sales {
  .average-sales-container {
    margin-left: 12px;
    height: 100% !important;
    .average-sales-title {
      color: #6a727a;
      margin-left: 10px !important;
    }
  }
}
.average-customer-title {
  color: #6a727a;
  padding-top: 3px;
}
.average-customer-cost {
  margin-top: -3px;
}
.color-bg {
  background-color: var(--colorBg);
}
.monthly-main svg path {
  fill: var(--colorSvg);
}
.monthly-main .bar {
  background-color: var(--colorSvg);
}
</style>
<style lang="scss" scoped>
.prominent {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
.title-pill {
  width: 350px;
  height: 70px;
}
</style>
