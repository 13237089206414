<template>
  <v-card class="mt-6">
    <div class="report-card-header px-4 py-4 text-center">
      <span class="card-number">{{ number }}</span>
      <span class="mb-0 font-18px">{{ title }}</span>
    </div>
    <div class="py-6 px-5">
      <slot></slot>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "reportCard",
  props: ["number", "title"]
};
</script>

<style lang="scss">
.report-card-header {
  border-bottom: thin solid #d9d9d9;
}
.card-number {
  display: inline-block;
  padding: 1px 10px;
  background: #333333;
  color: white;
  border-radius: 1px;
  font-size: 12px;
  font-weight: 900;
  position: absolute;
  left: 16px;
  top: 20px;
}
</style>
